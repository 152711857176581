<template>
  <v-container v-if="uc != null" class="m-0 p-0">
    <v-row dense align="center" justify="start">
      <v-col cols="12">
        <i v-i:duo#graduation-cap#16 class="ms-1" v-cHex="cor.active.P"></i>
        <span v-cHex="cor.active.P" class="m-0 p-0 ms-0 me-0 f-opensans fw-500 fsp-n1 fs-10pt line-height-1 text-truncate">
          <span class="ms-1 me-1 fw-700 f-sanspro fs-9pt">UC:</span>
          {{uc.nome}}
        </span>
        <span v-for="(area, key, index) in uc.areas" :key="refresh+key">
          <tagarea class="me-1" :sigla="area.sigla"/>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "uclabel",
  components: {
    'tagarea': () => import('@/views/cursos/tagArea.vue'),
  },
  props: {
    ucID: { default: "", type: String }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      uc: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      if(this.ucID != '') {
        rdb.ref('/curriculo/ucs/'+this.ucID).get().then(function(snapshot) {
          self.uc = snapshot.val();
          console.log("self.uc",self.uc);
        });
      }
    }
  }
}
</script>

<style scoped>
</style>
